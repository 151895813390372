*{
  padding: 0%;
  margin: 0%;
}


input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #3b82f6; /* This is Tailwind's blue-500 color */
}

input:checked ~ .block {
  background-color: #d1d5db; /* This is Tailwind's gray-300 color */
}

#MapData {
  overflow-y: scroll;
}

#MapData::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

#MapData::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#MapData::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1; 
}

#MapData::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}




.hiddenSidebar{
  margin-top: 2px;
  height: 135vh;
}
.hiddenSidebarTrack{
  margin-top: 2px;
  height: 105vh;
}

.h-87vh {
  height: 87vh;
}

.h-90vh {
  height: 90vh;
}

.h-97{
  height: 97%;
}

.mt-2px{
  margin-top: 2px;
}

.bordertop{
  border-top: 1px solid #E6E6E6;
}

.HeadingPre{background-color: #D9D9D9 }